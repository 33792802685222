<template>
  <div class="not-found">
    <span>404 not found</span>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
}

.not-found span {
  font-size: 36px;
  text-align: center;
}
</style>
